import { Dialog as DialogCore } from '@material-ui/core';
import IconClose from 'assets/svg/icons/close.inline.svg';
import IconButton from 'components/Atoms/IconButton';
import React from 'react';
import tw, { css,styled } from 'twin.macro';

const StyledDialog = styled(DialogCore)`
	&& {
		${({ showNavigation }) => !showNavigation && css`
			z-index: 999999999999 !important;
		`}
	
		.MuiPaper-root {
			${tw`rounded-15 border border-solid border-grey-7 w-18/20 max-w-300 min-h-100`};
		}
	}
`;

export default function PopupContainer({ isOpen, showNavigation, className, onClose, children }) {
	return (
		<StyledDialog onClose={onClose} open={isOpen} showNavigation={showNavigation}>
			<IconButton Component={IconClose} onClick={onClose} size='1rem' tw='fill-current text-dark absolute right-0 top-0 mr-03 mt-03' />
			<div className={className}>
				{children}
			</div>
		</StyledDialog>
	);
}
