import moment from 'moment/moment';
import PropTypes from 'prop-types';
import HeaderContext from 'providers/HeaderProvider';
import * as R from 'ramda';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetState } from 'react-use';
import { cutEmptySpaces, hasValidEmail, hasValidFieldNumber, hasValidPolandNip, isAnyEmpty, varToString } from 'utils/helper';

// for input's type="number"
// onInput={(e) => setMaxLength(e, 5);
// min={0}
export const setInputMaxLength = (e, number) => {
	const value = e.target.value;

	if(value) {
		e.target.value = Math.max(0, cutEmptySpaces(value)).toString().slice(0, number);
	}
};

export default function useInputHandlers(initValues) {
	const { header, setFixedHide } = useContext(HeaderContext);
	const { t } = useTranslation();

	let initErrorValues = {};

	Object.keys(initValues).forEach(item => {
		initErrorValues[item] = '';
	});

	const [values, setValues] = useSetState(initValues);
	const [errorMessages, setErrorMessages] = useSetState(initErrorValues);

	const checkRequireAutocomplete = (options, name, value) => {
		handleInputBlur();

		let errorMessage = '';

		if(hasDefaultHandlingPassed(value)) {
			const condition = options && value && !options.find(option => R.path(['name'], option) === value);
			errorMessage = condition ? 'Wybierz opcję z listy' : '';
		} else {
			errorMessage = t('error.message.fieldRequired');
		}

		setErrorMessages({ [name]: errorMessage });
		return errorMessage;
	};

	const handleAutocompleteInputChange = (options, name) => ({ target: { value }}) => {
		setValues({ [name]: value });
	};

	const handleAutocompleteChange = (options, name) => ({ target: { innerText }}) => {
		setErrorMessages({ [name]: '' });
		setValues({ [name]: innerText });
	};

	const checkRequireAutocompleteFlag = (options, name, value) => {
		const condition = options && value && !options.find(option => R.path(['name'], option) === value);
		const errorMessage = condition ? 'Wybierz opcję z listy' : '';
		setErrorMessages({ [name]: errorMessage });

		return errorMessage;
	};

	const handleAutocompleteFlagChange = name => ({ target: { innerText }}) => {
		setErrorMessages({ [name]: '' });
		setValues({ [name]: innerText });
	};

	const handleAutocompleteInputFlagChange = name => ({ target: { value }}) => {
		setValues({ [name]: value });
	};

	const handleAutocompleteBlur = (options, name, inputValue) => {
		const foundOption = options.find(option => inputValue.includes(R.path(['name'], option)));
		setErrorMessages({ [name]: foundOption ? '' : 'Wybierz kraj z listy' });
	};

	const handleSelectBlur = useCallback((options, errorText = 'To pole jest wymagane') => ({ target: { name, value }}) => {
		const foundOption = options.find(option => R.path(['name'], option) === value);
		setErrorMessages({ [name]: foundOption ? '' : errorText });
	}, [errorMessages]);

	const [isCheckboxChange, setCheckboxChange] = useState(false);
	const hasDefaultHandlingPassed = value => !isAnyEmpty(value);

	const validateDefault = (name, value, fieldAmount = 3) => {
		let errorMessage = '';

		if(hasDefaultHandlingPassed(value)) {
			errorMessage = value.length >= fieldAmount ? '' : t('error.message.fieldLength');
		}

		setErrorMessages({ [name]: errorMessage });
		return errorMessage;
	};

	const validateRequiredDefault = (name, value, fieldAmount = 3) => {
		let errorMessage = '';

		if(moment.isDate(value)) {
			errorMessage = '';
		} else if(hasDefaultHandlingPassed(value)) {
			errorMessage = value.length >= fieldAmount ? '' : t('error.message.fieldLength');
		} else {
			errorMessage = t('error.message.fieldRequired');
		}

		setErrorMessages({ [name]: errorMessage });
		return errorMessage;
	};

	//phone min 4 max 15
	const checkPhoneLength = useCallback(({ target: { name, value }}) => {
		handleInputBlur();

		let errorMessage = '';

		if(hasDefaultHandlingPassed(value)) {
			const condition = (value.length > 3 && value.length < 20);
			errorMessage = condition ? '' : 'Podany numer posiada nieprawidłową długość';
		}	else {
			errorMessage = t('error.message.fieldRequired');
		}

		setErrorMessages({ [name]: errorMessage });
		return errorMessage;
	}, [errorMessages]);

	const checkPolandPhoneLength = ({ target: { name, value }}) => {
		handleInputBlur();

		if(hasDefaultHandlingPassed(value)) {
			const condition = (value.length === 11);
			setErrorMessages({ [name]: condition ? '' : 'Podany numer posiada nieprawidłową długość' });
			return condition;
		}	else {
			setErrorMessages({ [name]: t('error.message.fieldRequired') });
			return false;
		}
	};

	const checkEmail = useCallback(({ target: { name, value }}) => {
		handleInputBlur();

		const errorMessage = hasValidEmail(value) ? '' : t('error.message.email');
		setErrorMessages({ [name]: errorMessage });

		return errorMessage;
	}, [errorMessages]);

	const checkNip = useCallback(({ target: { name, value }}) => {
		handleInputBlur();

		let errorMessage = '';

		if(hasDefaultHandlingPassed(value)) {
			const isValidNip = header.isLimit ? hasValidPolandNip(value) : (value && value.length >= 5);
			errorMessage = isValidNip ? '' : t('error.message.nip');
		} else {
			errorMessage = t('error.message.fieldRequired');
		}
		setErrorMessages({ [name]: errorMessage });

		return errorMessage;
	}, [errorMessages, header]);

	const checkPolandNip = useCallback(({ target: { name, value }}) => {
		handleInputBlur();

		let errorMessage = '';

		if(hasDefaultHandlingPassed(value)) {
			const isValidNip = hasValidPolandNip(value);
			errorMessage = isValidNip ? '' : t('error.message.nip');
		} else {
			errorMessage = t('error.message.fieldRequired');
		}
		setErrorMessages({ [name]: errorMessage });

		return errorMessage;
	}, [errorMessages, header]);

	const checkRequireText = ({ target: { name, value }}) => {
		handleInputBlur();

		return validateRequiredDefault(name, value);
	};

	const checkRequireCountry = ({ target: { name, value }}) => {
		return validateRequiredDefault(name, value, 1);
	};

	const checkRequireNumber = useCallback(({ target: { name, value }}) => {
		handleInputBlur();

		let errorMessage = '';

		if(!hasDefaultHandlingPassed(value)) {
			errorMessage = t('error.message.fieldRequired');
		} else if(value.startsWith('0')) {
			errorMessage = 'Niepoprawnie wypełnione pole.';
		}
		setErrorMessages({ [name]: errorMessage });

		return errorMessage;
	}, [errorMessages]);

	const handleInputFocus = useCallback(() => {
		setFixedHide(true);
	}, []);

	const handleInputBlur = useCallback(() => {
		setFixedHide(false);
	}, []);

	const handleTextChange = useCallback(({ target: { name, value }}) => {
		setValues({ [name]: value });
	}, [values]);

	const handleSelectChange = useCallback(({ target: { name, value }}) => {
		setErrorMessages({ [name]: ''});
		setValues({ [name]: value });
	}, [values, errorMessages]);

	const updatePhoneNumber = useCallback(({ name, value }) => {
		const ValueWithEmptySpace = value.replace(/([^\d]+)/g, '').replace(/(\d{3})/g, '$1 ').trim();
		setValues({ [name]: ValueWithEmptySpace });

		return ValueWithEmptySpace;
	}, [values]);

	const handleCheckboxChange = ({ target: { name, checked }}) => {
		setValues({ [name]: checked });
		setCheckboxChange(true);
	};

	const getInvalidFields = array => {
		return array.filter(item => item).filter(({ object, validateFunction }) => {
			const valueName = varToString(object);
			return validateFunction({ target: { name: valueName, value: object[valueName] }});
		});
	};

	const handlePhoneNumberChange = useCallback(({ target }) => {
		(!target.value.length || hasValidFieldNumber(target.value)) && updatePhoneNumber(target);
	}, [values]);

	// handleDateChange with reset day of current date
	const handleDateChange = useCallback(name => date => {
		const newDate = new Date(new Date(moment(date).valueOf()).setDate(1));

		setErrorMessages({ [name]: '' });
		setValues({ [name]: newDate });
	}, [values]);

	return {
		values,
		setValues,
		errorMessages,
		setErrorMessages,
		isCheckboxChange,
		handleInputFocus,
		handleInputBlur,
		handleTextChange,
		handleSelectChange,
		handlePhoneNumberChange,
		handleCheckboxChange,
		handleAutocompleteChange,
		handleAutocompleteInputChange,
		handleAutocompleteInputFlagChange,
		handleAutocompleteFlagChange,
		handleAutocompleteBlur,
		handleDateChange,
		handleSelectBlur,
		checkRequireText,
		checkRequireCountry,
		checkRequireNumber,
		checkEmail,
		checkNip,
		checkPolandNip,
		checkPhoneLength,
		checkPolandPhoneLength,
		checkRequireAutocomplete,
		getInvalidFields
	};
}

useInputHandlers.propTypes = {
	initValues: PropTypes.object.isRequired
};
