import { Tooltip as TooltipCore } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import React, { useState } from 'react';

const useStyles = makeStyles({
	arrow: {
		color: 'var(--color-primary)',
		marginLeft: '0px !important'
	},
	tooltip: {
		minHeight: '8rem',
		minWidth: '90vw',
		lineHeight: '1rem',
		display: 'flex',
		fontSize: '1rem',
		padding: '0.8rem',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'var(--color-primary)'
	},
	tooltipPlacementTop: {
		marginBottom: '0.5rem',
		marginRight: '0.35rem'
	}
});

export default function TooltipAgreement({ onClick, ...props }) {
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	const handleTooltipToggle = () => {
		setOpen(prev => !prev);
		handleClick();
	};

	const handleClick = () => {
		onClick && onClick();
	};

	return (
		<div {...props} className='flex items-center justify-center'>
			<TooltipCore
				arrow
				open={open}
				classes={classes}
				interactive
				leaveTouchDelay={120000}
				TransitionComponent={Zoom}
				placement="top-end"
				title={(
					<p className='text-08 font-semibold bg-primary'>
							Wyrażam zgodę na używanie przez Smart Faktor SA z siedzibą w Warszawie przy ulicy Prostej 70,
							telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem,
							dla celów kontaktu przedstawiciela Smart Faktor SA oraz marketingu bezpośredniego
							produktów własnych zgodnie z art. 172 ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. 2004 nr 171 poz. 1800)
							oraz na przesyłanie informacji handlowej środkami komunikacji elektronicznej na podany przeze mnie adres poczty elektronicznej,
							zgodnie z ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. z 2002 r. Nr 144, poz. 1204 z późn. zm.).
							Oświadczam, że zostałam/em poinformowana/y o dobrowolności wyrażenia powyższej zgody oraz o możliwości jej odwołania w każdym czasie.
							Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania danych, którego dokonano na podstawie zgody przed jej wycofaniem.
							Administratorem podanych danych będzie Smart Faktor SA z siedzibą w Warszawie przy ul. Prostej 70.
							Dane zostały podane dobrowolnie z prawem dostępu do ich treści i poprawienia. Prosimy o zapoznanie się z
							pełną klauzulą informacyjną Smart Faktor SA dostępną w sekcji Regulaminy.
					</p>
				)}
			>
				<div onClick={handleTooltipToggle}><p className='text-08 font-lat font-semibold underline text-blue-0'>{open ? 'zwiń' : 'rozwiń'}</p></div>
			</TooltipCore>
		</div>
	);
}
