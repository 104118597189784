import { Checkbox as CheckboxCore } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { val } from 'utils/helper';

const useStyles = makeStyles({
	icon: {
		borderRadius: 5,
		width: 18,
		height: 18,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 18,
			height: 18,
			backgroundImage:
				'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath' +
				' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ' +
				'1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
			content: '""',
		}
	}
});

export function Checkbox({ color, ...props }) {
	const colorValue = val(color);

	return (
		<CheckboxCore
			style={{ color: colorValue }}
			inputProps={{
				onInvalid: ({ target }) => target.setCustomValidity('Wymagana zgoda.'),
				onInput: ({ target }) => target.setCustomValidity('')
			}}
			{...props}
		/>
	);
}

Checkbox.defaultProps = {
	color: 'main'
};

export function CheckboxCustom({ color, ...props }) {
	const colorValue = val(color);
	const classes = useStyles();

	return (
		<CheckboxCore
			icon={<span className={classes.icon} />}
			checkedIcon={<span style={{ backgroundColor: colorValue }} className={clsx(classes.icon, classes.checkedIcon)} />}
			inputProps={{
				onInvalid: ({ target }) => target.setCustomValidity('Wymagana zgoda.'),
				onInput: ({ target }) => target.setCustomValidity('')
			}}
			{...props}
		/>
	);
}

CheckboxCustom.defaultProps = {
	color: 'main'
};

