import { IconButton as IconButtonCore } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export default function IconButton({ Component, iconClass, size, ...props }) {
	return (
		<IconButtonCore {...props}>
			<Component className={iconClass} width={size} height={size} />
		</IconButtonCore>
	);
}

IconButton.propTypes = {
	Component: PropTypes.oneOfType([
		PropTypes.object.isRequired,
		PropTypes.func.isRequired
	])
};
