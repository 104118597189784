import DialogContainer from 'components/Atoms/PopupContainer';
import Button from 'components/Molecules/Button';
import PropTypes from 'prop-types';
import React from 'react';

function PopupDefault({ isOpen, title, onClose, ...props }) {
	return (
		<DialogContainer {...props} className='flex flex-col items-center justify-center p-10' isOpen={isOpen} onClose={onClose}>
			<p className='text-center font-bold text-14 my-20'>Informacja</p>
			<p className='text-center'>{title}</p>

			<p className='text-center font-semibold mb-20'>Dziękujemy i pozdrawiamy serdecznie.</p>
			<Button onClick={onClose} primary><p>Ok</p></Button>
		</DialogContainer>
	);
}

PopupDefault.propTypes = {
	onClose: PropTypes.func.isRequired
};

PopupDefault.defaultProps = {
	title: 'Wiadomość została wysłana.'
};

export default React.memo(PopupDefault);
