import { TextField as TextFieldCore } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import React from 'react';
import tw, { css } from 'twin.macro';
import { dismissOnSubmitKey } from 'utils/helper';

const primaryStyles = css`
	${tw`w-full rounded-10`};
	
	fieldset {
		${tw`rounded-10`};
	}
`;

export default function TextField({ children, InputProps, loading, disabled, inputProps, dismissOnSubmit, ...props }) {
	return (
		<TextFieldCore
			{...props}
			css={[ primaryStyles ]}
			disabled={loading || disabled}
			InputProps={{
				...InputProps,
				endAdornment: loading && (
					<InputAdornment className={props.select && 'mr-10'} position="end">
						<CircularProgress size='1rem' color='secondary' />
					</InputAdornment>
				)
			}}
			inputProps={{
				...inputProps,
				onKeyPress: dismissOnSubmit ? dismissOnSubmitKey : () => {}
			}}
		>
			{children}
		</TextFieldCore>
	);
}

TextField.propTypes = {
	label: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired,
	error: PropTypes.bool,
	helperText: PropTypes.node,
	variant: PropTypes.string,
	type: PropTypes.string,
	inputProps: PropTypes.object,
	fullWidth: PropTypes.bool
};

TextField.defaultProps = {
	inputProps: { maxLength: 50 },
	dismissOnSubmit: false,
	variant: 'outlined',
	margin: 'normal',
	type: 'text'
};
