import { useMutation, useQuery } from '@apollo/client';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Skeleton from '@material-ui/lab/Skeleton';
import { navigate } from '@reach/router';
import { GET, SEND } from 'api/queries';
import IconEmail from 'assets/svg/icons/email.inline.svg';
import IconPhone from 'assets/svg/icons/phone.inline.svg';
import Button from 'components/Molecules/Button';
import { CheckboxCustom } from 'components/Molecules/material-ui';
import TextField from 'components/Molecules/material-ui/TextField';
import TooltipAgreement from 'components/Molecules/material-ui/TooltipAgreement';
import PopupDefault from 'components/Molecules/popups/PopupDefault';
import SubHeader from 'components/Organisms/SubHeader';
import { routes } from 'constants/routes';
import useInputHandlers from 'hooks/useInputHandlers';
import HeaderContext from 'providers/HeaderProvider';
import React, { useContext, useEffect, useRef, useState } from 'react';
import tw, { css } from 'twin.macro';
import { formatFundsEurop, formatNthString } from 'utils/helper';

const primaryStyles = css`
	${tw`flex flex-col items-center justify-center bg-light border border-solid px-15 py-20 mx-15 my-14 rounded-10 border-main`};
	> svg {
		${tw`fill-current text-main w-40 h-40`};
	}
	h1 {
		${tw`text-center font-semibold text-dark mt-12 mb-10`};
	}
	h2 {
		${tw`font-semibold text-dark mr-05`};
	}
	h3 {
		${tw`text-08 font-lat text-grey-9`};
	}
	.item {
		${tw`w-full flex items-start justify-start my-05`};
	}
`;

function ContactPanel({ Element = 'div', href, children, ...props }) {
	return (
		<Element href={href} css={[ primaryStyles ]} {...props}>
			{children}
		</Element>
	);
}

export default function contact(props) {
	const [isPopupOpen, setPopupOpen] = useState(false);
	const { setHeader } = useContext(HeaderContext);
	const contactRef = useRef();

	const { error: errorFetch, data } = useQuery(GET.CONTACT_DATA, {
		fetchPolicy: 'cache-and-network'
	});

	if(errorFetch) navigate(routes.SERVER_ERROR);

	const {
		values, errorMessages,
		handleTextChange, handlePhoneNumberChange, handleCheckboxChange,
		checkEmail, checkRequireText, checkPhoneLength,
		getInvalidFields
	} = useInputHandlers({
		name: '',
		email: '',
		phone: '',
		subject: '',
		message: '',
		sendCopyChecked: false,
		isAcceptedTermsAndConditions: false
	});

	useEffect(() => {
		setHeader({ isMain: true });

		if(props?.location?.search) {
			scrollInto(contactRef);
		}
	}, []);

	const [ sendContact, mutationContact ] = useMutation(SEND.CONTACT_FORM, {
		onCompleted: () => {
			setPopupOpen(true);
		},
		onError: error => {
			console.log('unhandled errors:', { error });
		}
	});

	const scrollInto = ref => {
		ref && ref.current && ref.current.scrollIntoView({ behavior: 'smooth' });
	};

	const handleSubmit = e => {
		e.preventDefault();

		const { name, email, phone, subject, message } = values;

		const invalidFields = getInvalidFields([
			{ object: { name }, validateFunction: checkRequireText },
			{ object: { email }, validateFunction: checkEmail },
			{ object: { phone }, validateFunction: checkPhoneLength },
			{ object: { subject }, validateFunction: checkRequireText },
			{ object: { message }, validateFunction: checkRequireText }
		]);

		if(!invalidFields.length) {
			sendContact({ variables: {
				name: name,
				email: email,
				phone: phone,
				title: subject,
				message: message,
				isCopyChecked: values.sendCopyChecked
			}});
		}
	};

	return (
		<div>
			<SubHeader Icon={IconPhone} title='Skontaktuj się z nami' />
			<PopupDefault isOpen={isPopupOpen} onClose={() => setPopupOpen(false)} />

			<ContactPanel Element='a' href={`tel:${formatNthString(data?.contactData?.phoneNumber)}`} tw='mt-20'>
				<IconPhone />
				<h1 className='w-1/2'>Zadzwoń do nas</h1>
				<p className='text-12 font-semibold text-tertiary'>{formatNthString(data?.contactData?.phoneNumber) ?? <Skeleton width='8rem' />}</p>
			</ContactPanel>

			<ContactPanel Element='a' href="mailto:biuro@smartfaktor.pl" target="_top">
				<IconEmail />
				<h1>Napisz do nas</h1>
				<p className='text-12 font-semibold text-tertiary'>{data?.contactData?.email ?? <Skeleton width='8rem' />}</p>
			</ContactPanel>


			<ContactPanel onSubmit={handleSubmit} Element='form' tw='relative pt-0'>
				<div ref={contactRef} className='absolute top-0 -mt-50' />
				<h1>Wyślij wiadomość</h1>

				<TextField
					label="Imię i nazwisko"
					type="text"
					name="name"
					autoComplete="on"
					value={values.name}
					onChange={handleTextChange}
					error={!!errorMessages.name}
					helperText={errorMessages.name}
					inputProps={{
						onBlur: checkRequireText,
						maxLength: 254
					}}
				/>

				<TextField
					label="Adres email"
					type="text"
					autoComplete="email"
					name="email"
					value={values.email}
					onChange={handleTextChange}
					error={!!errorMessages.email}
					helperText={errorMessages.email}
					InputProps={{
						onBlur: checkEmail,
						maxLength: 254
					}}
				/>

				<TextField
					label="Twój numer telefonu"
					type='tel'
					name="phone"
					autoComplete="tel-national"
					value={values.phone}
					error={!!errorMessages.phone}
					helperText={errorMessages.phone}
					onChange={handlePhoneNumberChange}
					InputProps={{
						onBlur: checkPhoneLength
					}}
				/>

				<TextField
					label="Temat"
					type="text"
					autoComplete="on"
					name="subject"
					value={values.subject}
					onChange={handleTextChange}
					error={!!errorMessages.subject}
					helperText={errorMessages.subject}
					inputProps={{
						onBlur: checkRequireText,
						maxLength: 254
					}}
				/>

				<TextField
					rows={5}
					multiline
					label="Treść wiadomości"
					type="text"
					autoComplete="off"
					name="message"
					value={values.message}
					onChange={handleTextChange}
					error={!!errorMessages.message}
					helperText={errorMessages.message}
					inputProps={{
						onBlur: checkRequireText,
						maxLength: 1250
					}}
				/>

				<FormControlLabel
					tw='w-full'
					control={
						<CheckboxCustom
							name="sendCopyChecked"
							checked={values.sendCopyChecked}
							value={values.sendCopyChecked}
							onChange={handleCheckboxChange}
						/>
					}
					labelPlacement="end"
					label={<h3>Wyślij kopię do siebie</h3>}
				/>

				<FormControlLabel
					tw='w-full my-10'
					control={
						<CheckboxCustom
							required
							name="isAcceptedTermsAndConditions"
							checked={values.isAcceptedTermsAndConditions}
							value={values.isAcceptedTermsAndConditions}
							onChange={handleCheckboxChange}
						/>
					}
					labelPlacement="end"
					label={
						<div className='flex items-start justify-start'>
							<p className='text-08 font-lat text-grey-9 mr-05'>Zgoda na kontakt  wraz z informacją o przetwarzaniu danych osobowych</p>
							<TooltipAgreement onClick={() => handleCheckboxChange({ target: { name: 'isAcceptedTermsAndConditions', checked: !values.isAcceptedTermsAndConditions }})} />
						</div>
					}
				/>

				<Button loading={mutationContact.loading} secondary type='submit' tw='bg-main'><p>Wyślij wiadomość</p></Button>
			</ContactPanel>

			{data?.contactData && (
				<ContactPanel primary tw='pt-0 pt-05 pb-12 mb-10'>
					<h1>Smart Faktor Spółka Akcyjna</h1>
					<div className='item'><h2>Siedziba:</h2><p>{data?.contactData?.headquarter}</p></div>
					<div className='item'><h2>Oddział:</h2><p>{data?.contactData?.department}</p></div>
					<div className='item'><h2>Telefon:</h2><p>{formatNthString(data?.contactData?.phoneNumber)}</p></div>
					<div className='item'><h2>Poczta elektroniczna:</h2><p>{data?.contactData?.email}</p></div>
					<div className='item'><h2>Strona internetowa:</h2><p>{data?.contactData?.webPage}</p></div>
					<div className='item'><h2>Sąd:</h2><p>{data?.contactData?.court}</p></div>
					<div className='item'><h2>KRS:</h2><p>{data?.contactData?.krs}</p></div>
					<div className='item'><h2>NIP:</h2><p>{data?.contactData?.nip}</p></div>
					<div className='item'><h2>REGON:</h2><p>{data?.contactData?.regon}</p></div>
					<div className='item'><h2>KAPITAŁ ZAKŁADOWY:</h2><p>{formatFundsEurop(data?.contactData?.shareCapital, 1, 'zł', '', '')}</p></div>
					<div className='item'><h2>KAPITAŁ WPŁACONY: </h2><p>{formatFundsEurop(data?.contactData?.paidInCapital, 1, 'zł', '', '')}</p></div>
				</ContactPanel>
			)}
		</div>
	);
}
