import { gql } from '@apollo/client';

const QUERY = {
	ALL_COUNTRIES: 'allCountries { edges { node { id, name, code } } }',
	ALL_INDUSTRIES: 'allIndustries { edges { node { id, name, order } } }',
	ALL_ACTIVITY_LEGAL_FORMS: 'allActivityLegalForms { edges { node { id, name } } }',
	ALL_CREDIT_TYPES: 'allCreditTypes { edges { node { id, name } } }',
	ALL_CREDIT_SECURITIES: 'allCreditSecurities { edges { node { id, name } } }',
	ALL_LEASE_OBJECTS: 'allLeaseObjects { edges { node { id, name } } }',
	ALL_FACTORS: 'allFactors { edges { node { id, name } } }',
	ALL_OTHER_DEBTS: 'allOtherDebts { edges { node { id, name } } }',
	ALL_SECURITY_TYPES: 'allSecurityTypes { edges { node { id, name, isDefault } } }',
	ALL_PAYMENT_ADVANCE_HEIGHTS: 'allPaymentAdvanceHeights { edges { node { id, advance } } }',
	ALL_RECIPIENTS_AMOUNT: 'allRecipientsAmount { edges { node { id, amount } } }',
	ALL_CURRENCIES: 'allCurrencies { edges { node { id, name, code, order, symbol, ratio } } }',
	ALL_EXCHANGE_RATES: 'allExchangeRates { edges { node { id, mid, date, currency } } }',
	ALL_NEWS: 'allNews(password: $password, after: $cursor) { edges { node { id, title, body, publicationDate } }, pageInfo { endCursor, hasNextPage } }',
	TERMS_OF_SERVICE: 'termsOfService { name, content, version }',
	RODO: 'rodoClause { name, content, version }',
	CONTACT_DATA: 'contactData { phoneNumber, email, headquarter, department, webPage, court, krs, nip, regon, shareCapital, paidInCapital }'
};

export const HAS = {
	NEWS_PASSWORD: gql`query hasNewsPassword { hasNewsPassword }`
};

export const GET = {
	ALL_CORE_OFFER: gql`
		query getAllCoreData {
			${QUERY.ALL_INDUSTRIES},
			${QUERY.ALL_SECURITY_TYPES},
			${QUERY.ALL_COUNTRIES},
			${QUERY.ALL_CURRENCIES},
			${QUERY.ALL_PAYMENT_ADVANCE_HEIGHTS},
			${QUERY.ALL_RECIPIENTS_AMOUNT}
		}
	`,
	ALL_CORE_ADVANCED_OFFER: gql`
		query getAllAdvancedCoreData {
			${QUERY.ALL_INDUSTRIES},
			${QUERY.ALL_SECURITY_TYPES},
			${QUERY.ALL_COUNTRIES},
			${QUERY.ALL_CURRENCIES},
			${QUERY.ALL_PAYMENT_ADVANCE_HEIGHTS},
			${QUERY.ALL_RECIPIENTS_AMOUNT},
			${QUERY.ALL_ACTIVITY_LEGAL_FORMS},
			${QUERY.ALL_CREDIT_TYPES},
			${QUERY.ALL_CREDIT_SECURITIES},
			${QUERY.ALL_LEASE_OBJECTS},
			${QUERY.ALL_FACTORS},
			${QUERY.ALL_OTHER_DEBTS}
		}
	`,
	ALL_COUNTRIES: gql`
		query getAllCountries($after: String) {
			${QUERY.ALL_COUNTRIES}
		}
	`,
	ALL_INDUSTRIES: gql`
		query getAllIndustries {
			${QUERY.ALL_INDUSTRIES}
		}
	`,
	ALL_SECURITY_TYPES: gql`
		query getAllSecurityTypes {
			${QUERY.ALL_SECURITY_TYPES}
		}
	`,
	ALL_CURRENCIES: gql`
		query getAllCurrencies {
			${QUERY.ALL_CURRENCIES}
		}
	`,
	ALL_PAYMENT_ADVANCE_HEIGHTS: gql`
		query getAllCurrencies {
			${QUERY.ALL_PAYMENT_ADVANCE_HEIGHTS}
		}
	`,
	ALL_RECIPIENTS_AMOUNT: gql`
      query getAllCurrencies {
          ${QUERY.ALL_RECIPIENTS_AMOUNT}
      }
	`,
	ALL_NEWS: gql`
      query getAllNews($password: String $cursor: String) {
          ${QUERY.ALL_NEWS}
      }
	`,
	TERMS_OF_SERVICE: gql`
      query getTermsOfService {
          ${QUERY.TERMS_OF_SERVICE}
      }
	`,
	RODO: gql`
      query getRodo {
          ${QUERY.RODO}
      }
	`,
	CONTACT_DATA: gql`
      query getContactData {
          ${QUERY.CONTACT_DATA}
      }
	`
};

export const CREATE = {
	OFFER: gql`
      mutation CreateOffer($type: String! $currency: ID! $industry: ID! $country: ID! $limitAmount: Int!
          $paymentAdvanceHeight: ID! $recipientsAmount: ID!
          $paymentDate: Int!  $securityTypes: [ID]!) {
          createOffer(input: {
              type: $type
              country: $country
              industry: $industry
              currency: $currency
              limitAmount: $limitAmount
              paymentAdvanceHeight: $paymentAdvanceHeight
              recipientsAmount: $recipientsAmount
              paymentDate: $paymentDate
              securityTypes: $securityTypes
              privacyPolicyTermsOfUseCookies: true
          }) {
              offer {
                  id
                  preparatoryCommission
                  rate
              }
              clientMutationId
              errors {
                  field, messages
              }
          }
      }
	`,
	PROPOSAL: gql`
      mutation CreateProposal(
				$offerId: ID
				$type: String!
				$currency: ID!
				$limitAmount: Int!
				$paymentAdvanceHeight: ID!
				$companyNip: String!
				$activityLegalForm: ID!
				$industry: ID!
				$securityTypes: [ID]!
				$companyCreatedDate: Date!
				$financialResult: String!
				$lastYearRevenue: Int!
				$lastYearIncome: Int
				$lastYearLoss: Int
				$country: ID!
				$recipients: String!
				$creditObligation: Boolean
				$creditType: ID
				$creditDebt: Int
				$creditSecurity: ID
				$leasingObligation: Boolean
				$leaseObject: ID
				$leaseDebt: Int
				$lastInstalmentDate: Date
				$factoringObligation: Boolean
				$factor: ID
				$factorLimitAmount: Int
				$factorCurrentDebt: Int
				$factorContractExpirationDate: Date
				$otherNonCommercialObligation: Boolean
				$otherDebts: ID
				$otherCurrentDebt: Int
				$finalRepaymentDate: Date
				$overduePublicLawObligation: Boolean
				$overduePublicLawDebtAmount: Int
				$enforcementProceedingsObligation: Boolean
			) {
          createProposal(input: {
              type: $type
              offer: $offerId
              currency: $currency 
              limitAmount: $limitAmount 
              paymentAdvanceHeight: $paymentAdvanceHeight 
              companyNip: $companyNip 
              activityLegalForm: $activityLegalForm 
              industry: $industry 
              securityTypes: $securityTypes 
              companyCreatedDate: $companyCreatedDate 
              financialResult: $financialResult
              lastYearRevenue: $lastYearRevenue
              lastYearIncome: $lastYearIncome 
              lastYearLoss: $lastYearLoss
              country: $country 
              recipients: $recipients 
              creditObligation: $creditObligation 
              creditType: $creditType 
              creditDebt: $creditDebt 
              creditSecurity: $creditSecurity 
              leasingObligation: $leasingObligation 
              leaseObject: $leaseObject 
              leaseDebt: $leaseDebt 
              lastInstalmentDate: $lastInstalmentDate 
              factoringObligation: $factoringObligation 
              factor: $factor 
              factorLimitAmount: $factorLimitAmount 
              factorCurrentDebt: $factorCurrentDebt 
              factorContractExpirationDate: $factorContractExpirationDate 
              otherNonCommercialObligation: $otherNonCommercialObligation 
              otherDebts: $otherDebts 
              otherCurrentDebt: $otherCurrentDebt 
              finalRepaymentDate: $finalRepaymentDate 
              overduePublicLawObligation: $overduePublicLawObligation 
              overduePublicLawDebtAmount: $overduePublicLawDebtAmount 
              enforcementProceedingsObligation: $enforcementProceedingsObligation
              privacyPolicyTermsOfUseCookies: true
          }) {
              proposal {
                  id
                  preparatoryCommission
                  creditDecision
                  country {
                      name
                  }
                  limitAmount
                  paymentAdvanceHeight {
                      advance
                  }
                  recipientsAmount {
                      amount
                  }
                  financialResult
                  recipients {
											id
                      debtorPaymentDeadline
                      currency {
                          name
                      }
                      rate
                  }
              }
              clientMutationId
              errors {
                  field, messages
              }
          }
      }
	`
};

export const SEND = {
	OFFER_RATING: gql`
      mutation OfferRating($offerId: String! $stars: Int!) {
          rateOffer(input: {
              offerId: $offerId
              stars: $stars
          }) {
							offerId
					}
      }
	`,
	PROPOSAL_RATING: gql`
      mutation ProposalRating($proposalId: String! $stars: Int!) {
          rateProposal(input: {
              proposalId: $proposalId
              stars: $stars
          }) {
							proposalId
					}
      }
	`,
	OFFER: gql`
      mutation SendOffer($id: String! $email: String! $phone: String! $userAcceptRules: Boolean! $type: String!) {
          sendOffer(input: {
              offerId: $id
              email: $email
              phone: $phone
              type: $type
              privacyPolicyTermsOfUseCookies: true
              contactForCommercialPurposes: $userAcceptRules
              rodoClause: $userAcceptRules
          }) {
              offerId
              clientMutationId
              errors {
                  field, messages
              }
          }
      }
	`,
	PROPOSAL: gql`
      mutation SendProposal($id: String! $email: String! $phone: String! $userAcceptRules: Boolean!) {
          sendProposal(input: {
              proposalId: $id
              email: $email
              phone: $phone
              privacyPolicyTermsOfUseCookies: true
              contactForCommercialPurposes: $userAcceptRules
              rodoClause: $userAcceptRules
          }) {
              proposalId
              clientMutationId
              errors {
                  field, messages
              }
          }
      }
	`,
	HELP_PROPOSAL: gql`
      mutation HelpProposal(
          $type: String!
          $email: String!
          $phone: String!
          $userAcceptRules: Boolean!
          $offerId: ID
          $currency: ID
          $limitAmount: Int
          $paymentAdvanceHeight: ID
          $companyNip: String
          $activityLegalForm: ID
          $industry: ID
          $securityTypes: [ID]
          $companyCreatedDate: Date
          $financialResult: String
          $lastYearRevenue: Int
          $lastYearIncome: Int
          $lastYearLoss: Int
          $country: ID
          $recipients: String
          $creditObligation: Boolean
          $creditType: ID
          $creditDebt: Int
          $creditSecurity: ID
          $leasingObligation: Boolean
          $leaseObject: ID
          $leaseDebt: Int
          $lastInstalmentDate: Date
          $factoringObligation: Boolean
          $factor: ID
          $factorLimitAmount: Int
          $factorCurrentDebt: Int
          $factorContractExpirationDate: Date
          $otherNonCommercialObligation: Boolean
          $otherDebts: ID
          $otherCurrentDebt: Int
          $finalRepaymentDate: Date
          $overduePublicLawObligation: Boolean
          $overduePublicLawDebtAmount: Int
          $enforcementProceedingsObligation: Boolean
      ) {
          helpProposal(input: {
              type: $type
							email: $email
							phone: $phone
              privacyPolicyTermsOfUseCookies: true
              contactForCommercialPurposes: $userAcceptRules
              rodoClause: $userAcceptRules
              offer: $offerId
              currency: $currency
              limitAmount: $limitAmount
              paymentAdvanceHeight: $paymentAdvanceHeight
              companyNip: $companyNip
              activityLegalForm: $activityLegalForm
              industry: $industry
              securityTypes: $securityTypes
              companyCreatedDate: $companyCreatedDate
              financialResult: $financialResult
              lastYearRevenue: $lastYearRevenue
              lastYearIncome: $lastYearIncome
              lastYearLoss: $lastYearLoss
              country: $country
              recipients: $recipients
              creditObligation: $creditObligation
              creditType: $creditType
              creditDebt: $creditDebt
              creditSecurity: $creditSecurity
              leasingObligation: $leasingObligation
              leaseObject: $leaseObject
              leaseDebt: $leaseDebt
              lastInstalmentDate: $lastInstalmentDate
              factoringObligation: $factoringObligation
              factor: $factor
              factorLimitAmount: $factorLimitAmount
              factorCurrentDebt: $factorCurrentDebt
              factorContractExpirationDate: $factorContractExpirationDate
              otherNonCommercialObligation: $otherNonCommercialObligation
              otherDebts: $otherDebts
              otherCurrentDebt: $otherCurrentDebt
              finalRepaymentDate: $finalRepaymentDate
              overduePublicLawObligation: $overduePublicLawObligation
              overduePublicLawDebtAmount: $overduePublicLawDebtAmount
              enforcementProceedingsObligation: $enforcementProceedingsObligation
          }) {
              proposal {
                  id
                  preparatoryCommission
                  creditDecision
                  country {
                      name
                  }
                  limitAmount
                  paymentAdvanceHeight {
                      advance
                  }
                  recipientsAmount {
                      amount
                  }
                  financialResult
                  recipients {
                      id
                      debtorPaymentDeadline
                      currency {
                          name
                      }
                      rate
                  }
              }
              clientMutationId
              errors {
                  field, messages
              }
          }
      }
	`,
	CONTACT_FORM: gql`
				mutation ContactForm($name: String! $email: String! $phone: String! $title: String! $message: String! $isCopyChecked: Boolean!) {
          contactForm(input: {
              name: $name
              email: $email
              phone: $phone
              title: $title
              message: $message
              yourselfCopy: $isCopyChecked
          }) {
              name
              email
              phone
              title
              message
              yourselfCopy
              clientMutationId
              errors {
                  field, messages
              }
          }
      }
	`
};
